.dropdown-menu {
  //min-width: 200px;
  left: 50%;
  right: auto;
  text-align: left;
  transform: translate(-50%, 0);

  &:before {
    position: absolute;
    top: -7px;
    left: 50%;
    right: auto;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #144888;
    border-left: 7px solid transparent;
    border-bottom-color: #144888;
    content: '';
  }

  li {
    border-bottom: solid 1px #E0B772;

    a
    {
      font-family: 'helvetica_condensedbold';
      font-size: 14px;
    };
  }


  .dropdown-menu-center
  {
    align-content: center;
  }
}
.dropdown-menu.columns-2 {
  min-width: 400px;
}
.dropdown-menu.columns-3 {
  min-width: 700px;
}
.dropdown-menu li
{
  border-bottom: solid 1px #E0B772;
  a {
    font-family: 'helvetica_condensedbold';
    padding: 5px 0;
    font-size: 14px;
    //font-weight: 300;
    white-space: nowrap;
  }
}
.multi-column
{
  padding: 0 30px;
}

.multi-column
{
  &>.row
  {
    &>.col-2
    {
      padding-top: 30px;
      padding-bottom: 30px;

      &:nth-child(even)
      {
        background:#144888;
      }
    }
  }
}
.multi-column-dropdown {
  list-style: none;
  //margin: 0px;
  padding: 0 5px;
}
.multi-column-dropdown li a {
  display: block;
  clear: both;
  line-height: 1.428571429;
  color: #FFF;
  //white-space: normal;

  @include hover-focus {
    color: $dropdown-link-hover-bg;
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .dropdown-menu.multi-column {
    min-width: 240px !important;
    overflow-x: hidden;
  }
}

.search-btn
{
  background-color: transparent;
  border: none;
  img
  {
    max-width: 25px;
  }
}