

.secondary-footer
{
  @include clearfix;
  @include make-row;

  margin-left: 0!important;
  margin-right: 0!important;

  h5
  {
    font-size: 16px;
    color: #144888;
    margin: 0 0 25px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: "Lato Black";
  }

  .section-a,
  .section-b
  {
    @include make-col-ready();
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    padding-left: 0!important;
    padding-right: 0!important;
  }
  .section-a
  {
    background-color: #E0B772;

    .footer-content
    {
      max-width: $content-width/2;
    }

    .cecil-sr-image
    {
      padding-top: 72px;
    }

    .cecil-sr-bio
    {
      padding: 50px 75px 0 0;


      p
      {
        font-size: 15px;
        color: #525252;
      }


      .cta
      {
        color: #ffffff;
        background: #0E3668;
        padding: 8px 22px;
        display: inline-block;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
  .section-b
  {
    background-color: #EFDBB8;

    &>div
    {
      padding: 50px 65px 65px;
    }

    h5
    {
      text-align: center;
      font-family: "Lato Black";
    }

    ul
    {
      li
      {
        border-bottom: #EBD1A5 solid 7px;
        a
        {
          display: block;
          padding: 25px 0;

          &:hover
          {
            color: #9F0E34;
          }
        }
      }
    }
  }
}

#colophon
{
  background-color: #213875;
  padding: 60px 0 40px;

  &,
  a
  {
    color: #FFF;
  }

  .content{
    padding-bottom: 60px;

    .social
    {
      padding: 50px 0 0;
      a
      {
        border: solid 1px #ffffff;
        border-radius: 50%;
        padding: 5px;
        &:hover
        {
          background: #ffffff;
          color: #000000;
        }
      }
    }
  }

  .content-wrapper
  {
    width: $content-width;
    margin: 0 auto;

    h5
    {
      font-size: 16px;
    }

    .affiliates
    {
      display: flex;
      a
      {

      }
      span
      {
        &.affiliate
        {
          display: block;
          background-image: url("../images/affiliate-sprite.png");
          background-repeat: no-repeat;

          &.miller-foundation
          {
            width: 202px;
            height: 82px;
          }
          &.miller-company
          {
            width: 76px;
            height: 82px;
            background-position: -313px, 0;
          }
        }
      }
    }

    .terms
    {
      border-top: 1px #FFF solid;
      padding-top: 25px;
      font-size: 14px;

      ul
      {
        padding-left: 0;
        display: flex;
        justify-content: flex-end;
        list-style-type: none;
        list-style-position: inside;
        li
        {

          a
          {
            display: block;
            padding: 0.5rem 1rem;
            @include hover {
              color: $link-hover-color;
              text-decoration: $link-hover-decoration;
            }
          }
        }
      }
    }
  }
}