#home-content
{
  background-image: url("../images/home-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 480px;
  display: flex;
  justify-content: center;

  &>div
  {
    max-width: 815px;
    text-align: center;
  }

  h5
  {
    line-height: 85px;
    color: #144888;
    text-transform: uppercase;
    font-family: "Lato Black";
  }
  .autograph
  {
    font-family: 'autograf';
    font-size: 50px;
  }
}

#home-carousel
{
  background-color: #1D58A2;
  background-image: url("../images/carousel-background.png");
  background-repeat: repeat-y;
  background-size: 100%;

  .wrapper-content
  {
    width: $content-width;
    margin: 0 auto;

    .practice-item
    {
      text-align: center;
      .icon-holder
      {
        color: #E0B772;
        line-height: 2em;
        font-size: 2rem;
        text-align: center;
        padding: 25px 0;

        img
        {
          display: inline-block;
          max-width: 55px;
        }
      }
      
      .item-title
      {
        &,a
        {
          font-size: 16px;
          font-weight: bold;
          color: #FFF;
          text-transform: uppercase;
        }
      }

      .item-description
      {
        text-align: center;
        padding: 0 45px;
        color: #D7D7D7;
      }
    }

    .jcarousel
    {
      ul
      {
        li
        {
          @include transition();
          &.one
          {
            background-color: #1D58A2;
          }
          &.two
          {
            background-color: #144888;
          }
          &.three
          {
            background-color: #0B3366;
          }
        }
      }
    }
  }
}