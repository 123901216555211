.cecil-jr-header-text
{
  text-transform: uppercase;
  font-family: 'helvetica_condensed_black';
  font-size: 55px;
  color: #FFF;
  display: flex;
  //justify-content: center;
  align-items: center;
  height: 100%;

  .text-name
  {
    margin-left: 40px;
    margin-top: -65px;
  }
}