/* Generated by Font Squirrel (https://www.fontsquirrel.com) on September 21, 2017 */

@font-face {
  font-family: 'helvetica_condensedbold';
  src: url('helvetica-condensed-bold-webfont.woff2') format('woff2'),
  url('helvetica-condensed-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'helvetica_condensed_black';
  src: url('helvetica-condensed-black-webfont.woff2') format('woff2'),
  url('helvetica-condensed-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'latoblack';
  src: url('lato-black-webfont.woff2') format('woff2'),
  url('lato-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'autograf';
  src: url('autograf_personaluseonly-webfont.woff2') format('woff2'),
  url('autograf_personaluseonly-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}