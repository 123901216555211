.main-content
{
  display: flex;
  justify-content: center;

  .content-holder
  {
    width: 100%;
    max-width: 1200px;

    color: #144888;

    p
    {
      .red
      {
        font-weight: bold;
      }

      a:not(.btn)
      {
        color: #9F0E34;
        &:hover
        {
          color: #E0B772;
        }
      }
    }



    .red
    {
      color: #9F0E34;
      //font-weight: bold;
    }

    .bar
    {
      margin-top: 50px;
      border-bottom: solid 7px #9F0E34;
    }
  }
}