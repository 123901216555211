.millers-tab
{
  text-align: center;
  max-width: 400px;

  span
  {
    display: block;
  }

  a
  {
    @include transition();

    display: block;

    &:hover, &.active
    {
      span
      {
        background: #144888;
      }

    }
    span
    {
      background: #898989;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      padding: 4px;
    }
  }
}

.year-list
{
  @include clearfix;
  padding: 10px 0;
  list-style: none;
  list-style-position: inside;

  li
  {
    float: left;

    &:before{
      content: "|";
      display: block;
      float: left;
      font-size: 10px;
      padding-top: 7px;
    }

    &:first-child
    {
      &:before
      {
        content: "";
      }
    }

    a
    {
      display: block;
      padding: 2px 5px;
      font-size: 14px;
      font-weight: bold;

      &:hover,
      &.active
      {
        color: #9F0E34;
      }
    }
  }
}


.publications
{
  ul
  {
    padding-left: 0;
    list-style: none;
    list-style-position: inside;

    li
    {
      @include clearfix;
      background: #EBEBEB;
      padding-left: 15px;
      margin-bottom: 5px;

      .btn
      {
        border-radius: 0;
      }
    }
  }
}

.miller-gallery
{
  .gallery-item
  {
    width: 200px;
    margin-bottom: 10px;

    img
    {
      max-width: 100%;
    }

    figcaption
    {
      display: none;
    }
  }
}