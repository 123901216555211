.video-list
{
  position: relative;
  padding-right: 10px;

  .frame
  {
    padding: 0;
    width: 100%;
    height: 400px;
  }
  .frame .items
  {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
  }
  .frame .items li
  {
    float: left;
    margin: 0 0 5px 0;
    padding: 0;
    width: 100%;
    background-color: #D7D7D7;

    a
    {
      @include transition();
      display: block;
      //height: 149px;
      position: relative;

      &.active
      {
        &:before
        {
          content: '';
          display: block;
          border: 5px solid rgba(159,14,52,.3);
          position: absolute;
          top: 0;
          bottom:0;
          left: 0;
          right: 0;
        }
        //background: #9F0E34;
      }

      img
      {
        width: 100%;
        height: 130px;
      }
    }
  }

  .scrollbar {
    width: 10px;
    height: 400px;

    position: absolute;
    right: 0;
    background: #ccc;
    line-height: 0;
    z-index: 10;
  }

  .scrollbar .handle {
    width: 100%;
    height: 100px;
    background: #9F0E34;
    cursor: pointer;
  }
  .scrollbar .handle .mousearea {
    position: absolute;
    top: 0;
    left: -10px;
    width: 22px;
    height: 100%;
  }
}