.news-item
{
  .news-date
  {
    color: #636363;
  }

  .more
  {
    text-transform: uppercase;
    color: $brand-danger;
  }
}

.news-pages
{
  padding: 10px 0;
}