#masthead
{
  background-color: $blue;
  background-image: url("../images/masthead-bg.jpg");
  background-size: 100%;

  .navbar-brand
  {
    img
    {
      max-width: 210px;
    }
  }

  .content-holder
  {
    max-width: $content-width;
    margin: 0 auto;
    //min-height: 575px;
  }

  .home-header
  {
    display: flex;
    align-items: center;
    height: 100%;

    .home-slogan
    {
      .slogan
      {
        line-height: 1;
        text-transform: uppercase;
        font-family: 'helvetica_condensed_black';
        font-size: 55px;
        color: #FFF;

        .orange
        {
          color: #E0B772;
        }
      }
      .tagline
      {
        line-height: 1;
        font-family: 'latoblack';
        color: #6DCFF6;
        font-size: 35px;
        text-transform: uppercase;
      }
    }
  }
}