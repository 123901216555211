.sb-search {
  position: relative;
  margin-top: 10px;
  width: 0%;
  min-width: 60px;
  height: 60px;
  float: right;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  transition: width 0.3s;
  -webkit-backface-visibility: hidden;
}

/* The input element on the search element  */
.sb-search-input {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 60px;
  margin: 0;
  z-index: 10;
  padding: 20px 65px 20px 20px;
  font-family: inherit;
  font-size: 20px;
  color: #2c3e50;
}


input[type="search"].sb-search-input {
  /* remove special webkit formatting on the search input */
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

/* browser specific placholder text color */
.sb-search-input::-webkit-input-placeholder {
  color: #fff;
}

.sb-search-input:-moz-placeholder {
  color: #fff;
}

.sb-search-input::-moz-placeholder {
  color: #fff;
}

.sb-search-input:-ms-input-placeholder {
  color: #fff;
}
/* format the search button and submit to share the same size and space */
.sb-icon-search,
.sb-search-submit  {
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}
/* hide the submit but leave it clickable */
.sb-search-submit {
  background: #fff; /* IE needs this */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
  filter: alpha(opacity=0); /* IE 5-7 */
  opacity: 0;
  color: transparent;
  border: none;
  outline: none;
  z-index: -1;
}

/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
  width: 600px;
}

.sb-search-open
{
  overflow: visible;

  .sb-search-input
  {
    color: #FFF;
    background-color:#E0B772 !important;
  }
}
/* add the "active" color on the search button */
.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {
  background: #E0B772;
  color: #fff;
  z-index: 11;
}

/* adjust z-index to make sure the search submit is clikacble and not below any other elements */
.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
  z-index: 90;
}

.algolia-autocomplete {
  width: 100%;
}
.algolia-autocomplete .aa-input, .algolia-autocomplete .aa-hint {
  width: 100%;
}
.algolia-autocomplete .aa-hint {
  color: #999;
}
.algolia-autocomplete .aa-dropdown-menu {
  width: 100%;
  background-color: #fff;
  border: 1px solid #999;
  border-top: none;
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
  cursor: pointer;
  padding: 5px 4px;
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
  background-color: #B2D7FF;
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em {
  font-weight: bold;
  font-style: normal;
}