.sidebar
{
  .widget
  {
    border: #E2E2E2 solid 6px;
    margin-bottom: 45px;
    padding: 18px;

    &.social
    {
      h6
      {
        font-size: 14px;
        text-align: left;

        .fa-twitter
        {
          color: #1da1f2;
        }
        .fa-facebook-official
        {
          color: #3b5998;
        }
      }
    }

    h6
    {
      color: #C5C5C5;
      text-transform: uppercase;
      text-align: center;
      font-family: "Times New Roman";
      font-size: 24px;
    }
  }

  .gallery
  {
    text-align: center;
    h5
    {
      position: relative;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Times New Roman";
      color: #C5C5C5;

      &:before
      {
        border-top: 2px solid #A2A2A2;
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        z-index: -1;
      }

      span {
        background: #fff;
        padding: 0 15px;
      }
    }
  }
}